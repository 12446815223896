<template>
  <div id="humanResources">
    <!-- 横幅图  -->
    <div class="human_banner">
      <el-image style="width: 100%;max-height:404px;border:0;" :src="bannerUrl" fit="cover"/>
    </div>
    <!--  主体内容  -->
    <div class="human_main">
      <div class="human_warp">
        <div class="human_list_warp">
          <el-table :data="data" style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand">
                  <div style="padding: 0 30px;" v-html="props.row.jobDescription"></div>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column
                label="职位名称"
                prop="jobName">
            </el-table-column>
            <el-table-column
                label="招聘部门"
                prop="jobDepartment">
            </el-table-column>
            <el-table-column
                label="工作地址"
                prop="workingPlace">
            </el-table-column>
            <el-table-column
                label="更新时间"
                prop="releaseTime">
            </el-table-column>
          </el-table>
        </div>
        <div class="human_phone">联系电话：{{baseInfo.coHumanLandline}}</div>
        <div class="human_pages_warp">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :hide-on-single-page="true"
              :page-size="10"
              layout="prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'humanResources',
  data() {
    return {
      //横幅图
      bannerUrl: "/statics/banner/humanResources/zxns.jpg",
      //基础信息
      baseInfo: {},
      //数据
      data: [],
      //总数
      total: 0
    }
  },
  watch: {
    //监听路由变化
    $route: {
      handler() {
        this.getBanners();
        this.getData();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //获取横幅图
    async getBanners() {
      let banners1 = await this.request.get('/banner/getInfo?key=' + this.config.key + "&bannerType=14")
      if (banners1.data && banners1.data.fileId) {
        this.bannerUrl = this.preview + banners1.data.fileId;
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.getHrList(val);
    },
    //获取数据
    getData() {
      this.getBaseInfo();
      this.getHrList(1);
    },
    //获取官网基本信息
    getBaseInfo() {
      this.request.get('/baseinfo/getInfo?key=' + this.config.key).then(res => {
        this.baseInfo = res.data;
      });
    },
    //获取数据
    getHrList(val) {
      this.request.get('/hr/list?key=' + this.config.key + "&pageNum=" + val + "&pageSize=10").then(res => {
        this.data = res.data;
        this.total = res.total;
      });
    }
  }
}
</script>
<style>
@import "../assets/styles/pages/humanResources.css";
</style>
